<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="89.81 238.01 155.05 155.01"
  >
    <path
      d="m229.74,337.97c-24.61,14.17-52.54,12.24-69.56-4.78-21.2-21.2-18.47-58.16,6.22-84.15l10.47-11.03-15.18,1.03c-40.3,2.71-71.88,36.47-71.88,76.86,0,42.52,34.6,77.12,77.12,77.12,31.3,0,59.27-18.69,71.26-47.63l6.67-16.13-15.12,8.72Zm-62.81,43.17c-35.98,0-65.25-29.27-65.25-65.25,0-29.02,19.28-54,45.98-62.26-19.22,29.26-18.26,65.56,4.13,87.95,16.9,16.91,42.22,21.87,66.81,14.19-12.15,15.79-31.05,25.37-51.67,25.37Z"
    ></path>
  </svg>
</template>

<script></script>

<style scoped>
svg {
  fill: var(--step-oos-txt);
  height: 70%;
}
</style>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="86 240.65 162 144"
  >
    <path
      d="m247.99,384.65H86l81-144,81,144Zm-141.8-11.82h121.59l-60.8-108.08-60.8,108.08Zm60.84-9.85c-3.65,0-6.58-2.93-6.58-6.5s2.93-6.5,6.58-6.5,6.5,2.94,6.5,6.5-2.93,6.5-6.5,6.5Zm4.45-19.67h-8.99l-1.7-43.35h12.37l-1.69,43.35Z"
    ></path>
  </svg>
</template>

<script></script>

<style scoped>
svg {
  fill: var(--step-3-txt);
  height: 70%;
}
</style>

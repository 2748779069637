<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="118 246.9 98 138"
  >
    <path
      d="m216,246.9h-98l34.5,69-34.5,69h98l-34.5-69,34.5-69Zm-19.14,11.83l-13.35,26.71c-3.91,3.55-9.35,6.81-16.51,6.81s-12.59-3.26-16.5-6.8l-13.36-26.71h59.71Zm-5.58,103.18c-7.64-7.42-16.83-14.46-24.28-14.46s-16.63,7.03-24.27,14.46l23-46h2.54l23,46.01Z"
    ></path>
  </svg>
</template>

<script></script>

<style scoped>
svg {
  fill: var(--step-2-txt);
  height: 70%;
}
</style>

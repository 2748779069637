<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="111.97 275.63 129.96 97.01"
  >
    <path
      d="m153.39,372.62l-39.67-39.67c-2.34-2.34-2.34-6.13,0-8.47,2.34-2.34,6.13-2.34,8.47,0l31.2,31.21,78.31-78.31c2.34-2.34,6.13-2.34,8.47,0s2.34,6.13,0,8.47l-86.78,86.78Z"
    ></path>
  </svg>
</template>

<script></script>

<style scoped>
svg {
  fill: var(--step-1-txt);
  height: 50%;
}
</style>

import './assets/main.css';

import { createApp } from 'vue';
import * as Sentry from '@sentry/vue';
import App from './App.vue';

const app = createApp(App);

const isDev = window.location.hostname === 'localhost';

Sentry.init({
  app,
  dsn: 'https://9de3dbfe368bf93d416d43da3ce0320b@o4507735776034816.ingest.de.sentry.io/4507735786586192',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.browserProfilingIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: [
    /^https:\/\/ubahnstatus\.bvg-fgi\.de/,
    /^https:\/\/dev\.ubahnstatus\.bvg-fgi\.de/,
  ],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  profilesSampleRate: 1.0,
  debug: isDev,
  enabled: !isDev,
});

app.mount('#app');

<template>
  <aside></aside>
</template>

<script></script>

<style scoped>
aside {
  height: var(--flatterband);
  width: 100%;
  background: repeating-linear-gradient(
    135deg,
    var(--black-color),
    var(--black-color) 20px,
    var(--bvg-color) 20px,
    var(--bvg-color) 40px
  );
}
</style>

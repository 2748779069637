<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="134.44 244.9 71.4 142"
  >
    <path
      d="m178.73,340.42v3.56h-22.94v-3.36c0-28.28,22.35-32.63,22.35-54.98,0-10.49-5.73-19.38-18.58-19.38-9.3,0-18.01,3.76-24.13,6.52l-.99-22.15c6.92-3.17,20.18-5.73,30.86-5.73,23.73,0,40.54,10.48,40.54,35.01,0,28.87-27.09,40.94-27.09,60.51Zm-11.47,46.48c-7.91,0-14.64-6.53-14.64-14.43s6.72-14.44,14.64-14.44,14.43,6.53,14.43,14.44-6.53,14.43-14.43,14.43Z"
    ></path>
  </svg>
</template>

<script></script>

<style scoped>
svg {
  fill: var(--step-wip-txt);
  height: 70%;
}
</style>
